.personal-information-form .MuiFormLabel-root{
    margin:0px 0;
    color: rgba(0, 0, 0, 0.26) !important;
    font-size: 16px !important;
}

.personal-information-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.74) !important;
    color: #1E7995 !important;
}

.personal-information-form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #1E7995 !important;
}

/* MuiInputBase-root MuiOutlinedInput-root  */

.personal-information-form .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: rgb(240, 77, 83) !important;
    border-left-width: 5px !important;
    border-left-style: solid !important;
}

.personal-information-form.MuiFormControl-marginNormal{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.personal-information-form.MuiFormControl-root{
    height: 100%;
}

.personal-information-form .MuiInputBase-input{
    font-size:0.9rem !important;
    color: #495057;
    font-weight: 400;
}

.personal-information-form .MuiInputBase-input.Mui-disabled{
    color: currentColor;
}

.personal-information-form button:focus{
    outline: none !important;
}

.personal-information-form .MuiFormHelperText-contained{
    margin: 0px;
}

@media only screen and (max-width: 1480px){
    .personal_info_block .doc_tab_content{
        padding: 0px 50px 0px 50px !important;
    }
}

@media only screen and (max-width: 1280px){
    .personal_info_block .doc_tab_content {
        padding: 15px 15px 15px !important;
    }
}

@media only screen and (max-width: 580px){
    .personal_info_block .doc_tab_content {
        padding: 15px 0 15px 0 !important;
    }
}