.form-submit-btn{
    color: #1E7995 !important;
    cursor: pointer;
}

.form-submit-btn:hover{
    color: #2b2f38 !important;
    cursor: pointer;
}

.cst_input_group .form-control:focus .cst_input_group.login-form-eye-icon{
    box-shadow: 0 0 0 0.2rem rgba(30, 121, 149, 0.25);
}