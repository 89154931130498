.FileInput {
  display: none;
}

.requested_date_text {
  float: right;
  padding-right: 20px;
}

.service_section_status {
  display: block;
  padding-top: 10px;
}

.sk-circle .service_section_spinner:before {
  background-color: #ffffff;
}
