a {
  cursor: pointer;
}

.services_card > span {
  margin-top: -5px;
}

.aservice_loader {
  position: absolute;
  left: 20px;
}


/* New Home CSS Starts */

/****  Home css here ****/
/* line 5, ../sass/custom/_custom.scss */
.card_services_link:hover .services_card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

/* line 10, ../sass/custom/_custom.scss */
.services_card {
  border-radius: 4px;
  border-color: #E6E6E6;
  margin-bottom: 20px;
  box-shadow: 0 2px 6px 0 rgba(240, 240, 241, 0.75);
  -webkit-box-shadow: 0 2px 6px 0 rgba(240, 240, 241, 0.75);
  -moz-box-shadow: 0 2px 6px 0 rgba(240, 240, 241, 0.75);
  transition: all .4s ease;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
}
/* line 20, ../sass/custom/_custom.scss */
.services_card .card-body {
  padding: 1.25rem 1.25rem 1.75rem;
}
/* line 23, ../sass/custom/_custom.scss */
.services_card .card-text {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333333;
}
/* line 27, ../sass/custom/_custom.scss */
.services_card .card-text .servie_title {
  min-width: 70px;
  display: inline-block;
}
/* line 31, ../sass/custom/_custom.scss */
.services_card .card-text:last-child {
  margin-bottom: 0;
}
/* line 36, ../sass/custom/_custom.scss */
.services_card.other_card h5.card-title {
  color: #333333;
  margin-bottom: 10px;
}
/* line 40, ../sass/custom/_custom.scss */
.services_card.other_card .card_details {
  padding: 10px;
  border-radius: 5px;
  background: #d6e7ee;
}
/* line 44, ../sass/custom/_custom.scss */
.services_card.other_card .card_details p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
}

/* line 52, ../sass/custom/_custom.scss */
.card_top_banner {
  text-align: center;
  position: relative;
  color: #fff;
  padding: 15px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 0 0;
}
/* line 62, ../sass/custom/_custom.scss */
.card_top_banner .card-title {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 18px;
  font-family: "Proxima Nova Alt Rg";
  position: relative;
  z-index: 1;
}
/* line 70, ../sass/custom/_custom.scss */
.card_top_banner .banner_top_img {
  position: absolute;
  right: 32px;
  height: 60px;
  object-fit: cover;
  top: calc(50% - 60px/2);
}

/* line 78, ../sass/custom/_custom.scss */
.banner_blue {
  background: rgba(89, 75, 176, 0.85);
}

/* line 81, ../sass/custom/_custom.scss */
.banner_red {
  background: rgba(222, 95, 96, 0.85);
}

/* line 84, ../sass/custom/_custom.scss */
.banner_pink {
  background: rgba(204, 67, 136, 0.85);
}

/* line 87, ../sass/custom/_custom.scss */
.banner_yellow {
  background: rgba(211, 174, 0, 0.85);
}

/* line 90, ../sass/custom/_custom.scss */
.banner_teal {
  background: rgba(52, 135, 135, 0.85);
}

/* line 93, ../sass/custom/_custom.scss */
.banner_gray {
  background: rgba(204, 204, 204, 0.85);
}

/* line 96, ../sass/custom/_custom.scss */
.banner_skyblue {
  background: #60b4d9;
}

/* line 99, ../sass/custom/_custom.scss */
.banner_darkgreen {
  background: rgba(162, 186, 66, 0.85);
}

/* line 102, ../sass/custom/_custom.scss */
.services_list_block {
  margin-bottom: 20px;
}
/* line 104, ../sass/custom/_custom.scss */
.services_list_block h3 {
  font-size: 22px;
  margin-bottom: 16px;
}

/**** // Home css here ****/
/**** persional services page css here ****/
/* line 113, ../sass/custom/_custom.scss */

.theme_nav_pills .nav-link:hover{
  color: #fff !important;
  background-color: #1E7995 !important;
}

.nav.theme_nav_pills {
  margin-bottom: 40px;
  margin-top: 40px;
}
/* line 116, ../sass/custom/_custom.scss */
.theme_nav_pills .nav-link {
  padding: 1rem 1.5rem;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #fff;
  min-width: 150px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}
/* line 129, ../sass/custom/_custom.scss */
.theme_nav_pills .nav-link.active {
  color: #fff;
  background-color: #1E7995;
  text-transform: uppercase;
}

/* line 136, ../sass/custom/_custom.scss */
.payments_fields {
  color: #bbbfc0;
  font-size: 13px;
  font-weight: 700;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* line 144, ../sass/custom/_custom.scss */
.payments_fields input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #bbbfc0;
  margin-top: -23px;
}

/* line 151, ../sass/custom/_custom.scss */
.add_business_wrp {
  margin-top: 30px;
  margin-bottom: 20px;
}
/* line 154, ../sass/custom/_custom.scss */
.add_business_wrp .btn {
  width: 400px;
  padding: 20px;
}

/* line 160, ../sass/custom/_custom.scss */
.finance_card ul.list-group {
  padding-left: 18px;
  padding-right: 18px;
}
/* line 163, ../sass/custom/_custom.scss */
.finance_card ul.list-group .list-group-item {
  text-align: center;
}
/* line 166, ../sass/custom/_custom.scss */
.finance_card ul.list-group a {
  font-weight: bold;
  font-size: 15px;
  color: #333333;
}
/* line 170, ../sass/custom/_custom.scss */
.finance_card ul.list-group a:hover {
  color: #1E7995;
}

/**** // Persional services page css here ****/


/* Media Query CSS  */

@media only screen and (max-width: 991px) {
  .theme_nav_pills .nav-link {
    padding: 1rem;
    min-width: 130px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .theme_nav_pills .nav-link {
      padding: 12px;
      min-width: 100px;
      font-size: 14px;
  }
}

@media only screen and (max-width: 580px) {

  .theme_nav_pills {
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .theme_nav_pills .nav-link {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .theme_nav_pills .nav-link br{
    display: none;
  }
  .add_business_wrp .btn {
    width: 100%;
    padding: 15px;
  }

}


/* New Home CSS Ends */