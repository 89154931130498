@media only screen and (max-width: 768px){
    .notifications_fp_inner_user_pro:before{
        width: 0px !important;
    }
    .notifications_fp_inner_user_pro{
        padding-top:10px !important;
        height: 100%;
        border-left: none;
        border-top: 1px solid #E6E6E6;
        padding-left: 0;
    }
    .notifications_fp_media_user_pro{
        left: 0;
        padding-left: 20px;
    }
    .billing_sc{
        padding: 0px 30px !important;
    }
}

@media only screen and (max-width: 991px){
    .notifications_fp_inner_user_pro:before{
        width: 0px !important;
    }
    .notifications_fp_inner_user_pro{
        padding-top:10px !important;
    }
}

@media only screen and (max-width: 580px){
    .billing_sc{
        padding: 0px 30px !important;
    }
}

.notifications_fp_media_user_pro{
    padding-top:100px;
    padding-bottom: 10px;
    position: relative;
    left: -30px;
    padding-left: 30px;
}

.notifications_fp_media_user_pro::before{
    top: 110px;
    background: url(/images/icons/icn_toolstyle.png);
    width: 25px;
    position: absolute;
    left: -32px;
    height: 36px;
    content: '';
    z-index: 99;
}

.notifications_fp_inner_user_pro{
    border-left: none;
    height: 100%;
    position: relative;
}

/* .notifications_fp_inner_user_pro:before{
    content: "";
    background-color: #E6E6E6;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 1px;
    height: 100vh;
} */

.notifications_right_content:before{
    content: "";
    background-color: #E6E6E6;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
}

.empty_details.notifications{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.empty_details.notifications .empty_img img{
    width: 100%;
}