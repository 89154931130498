.date-filter .MuiIconButton-root{
    padding: 0px !important;
    padding-bottom: 6px !important;
}

.MuiIconButton-root:hover{
  background-color: transparent !important;
}

.date-filter button:focus{
    outline: none !important;
}

.MuiPickersCalendar-week .MuiIconButton-root:hover{
  background-color: #3f51b5 !important;
}

.upload-dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .upload-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .upload-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f1f1f1;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .upload-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .upload-dropdown-content a:hover {background-color: #ddd;}
  
  .upload-dropdown:hover .upload-dropdown-content {display: block;}
  
  .upload-dropdown:hover .upload-dropbtn {background-color: #1E7995;}

  .modal-close-button:focus{
    box-shadow: none !important;
  }

  .upload-document-form .MuiFormLabel-root{
    margin:0px 0;
    color: rgba(0, 0, 0, 0.26) !important;
    font-size: 16px !important;
}

.upload-document-form .MuiFormLabel-root.ein{
    font-size: 14px !important;
}

.upload-document-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.75) !important;
    color: #1E7995 !important;
}

.upload-document-form.select-field .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -5px) scale(0) !important;
  color: #1E7995 !important;
}

.upload-document-form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #1E7995 !important;
}
/* MuiInputBase-root MuiOutlinedInput-root  */

.upload-document-form .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: rgb(240, 77, 83) !important;
    border-left-width: 5px !important;
    border-left-style: solid !important;
}

.upload-document-form.MuiFormControl-marginNormal{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.upload-document-form .MuiInputBase-input{
    font-size:0.9rem !important;
    color: #495057;
    font-weight: 400;
}
.upload-document-form.error .MuiInputBase-input{
  border-color: rgb(240, 77, 83) !important;
  border-left-width: 5px !important;
  border-left-style: solid !important;
  border: 1px solid rgb(240, 77, 83);
}
.close-upload-document-modal:focus{
  outline: none;
}

.date-filter .MuiFormHelperText-root{
  font-size: 0.6rem;
}