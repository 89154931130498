.billing-information-form .MuiFormLabel-root{
    margin:0px 0;
    color: rgba(0, 0, 0, 0.26) !important;
    font-size: 16px !important;
}

.billing-information-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.74) !important;
    color: #1E7995 !important;
}

.billing-information-form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #1E7995 !important;
}

/* MuiInputBase-root MuiOutlinedInput-root  */

.billing-information-form .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: rgb(240, 77, 83) !important;
    border-left-width: 5px !important;
    border-left-style: solid !important;
}

.billing-information-form.MuiFormControl-marginNormal{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.billing-information-form .MuiInputBase-input{
    font-size:0.9rem !important;
    color: #495057;
    font-weight: 400;
}

.billing-form-dropbtn {
    background-color: white;
    color: #1E7995;
    font-size: 16px;
    border: none;
  }
  
  .billing-form-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .billing-form-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f1f1f1;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
.billing-form-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.billing-form-dropdown-content a {background-color: #ddd;color: #1E7995;}

.billing-form-dropdown .billing-form-dropdown-content {display: block;}

.billing-form-dropdown .billing-form-dropbtn {background-color: #1E7995;}

.account_billing_info::before{
  top: 140px !important;
}

.billing-information-form .MuiInputBase-input.Mui-disabled{
  color: currentColor;
}


.billing-information-form button:focus{
  outline: none !important;
}

.close_existing_billing_form_button{
  background: none;
  outline: none;
  border: none;
  float: right;
}
.close_existing_billing_form_button:focus{
    outline: none;
}