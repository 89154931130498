.business-information-form .MuiFormLabel-root{
    margin:0px 0;
    color: rgba(0, 0, 0, 0.26) !important;
    font-size: 16px !important;
}

.business-information-form .MuiFormLabel-root.ein{
    font-size: 14px !important;
}

.business-information-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.74) !important;
    color: #1E7995 !important;
}

.business-information-form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #1E7995 !important;
}

/* MuiInputBase-root MuiOutlinedInput-root  */

.business-information-form .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: rgb(240, 77, 83) !important;
    border-left-width: 5px !important;
    border-left-style: solid !important;
}

.business-information-form.MuiFormControl-marginNormal{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.business-information-form .MuiInputBase-input{
    font-size:0.9rem !important;
    color: #495057;
    font-weight: 400;
}

.business-information-form .MuiInputBase-input.Mui-disabled{
    color: currentColor;
}

.business_information .add_info_box .item-title.business-info.fp_ativity_icons{
    font-weight: 400;
    font-size: 14px;
  }

.account_business_info::before{
    top: 75px !important;
  }

.close_existing_business_form_button{
    background: none;
    outline: none;
    border: none;
    margin-right: 20px;
    margin-left: auto;
  }
  .close_existing_business_form_button:focus{
      outline: none;
  }

  @media only screen and (min-width: 769px) {
    .close_existing_business_form_button{
        margin-bottom: 10px;
    }
  }

  @media only screen and (width: 768px) {
      .close_existing_business_form_button{
        width: 80%;
        position: absolute;
        margin-top: -20px;
      }
  }