.validate-account .MuiInputBase-root{
    height: 45px;
}

.validate-account .MuiButtonBase-root{
    margin-top: 5px;
}

.validate-account .MuiSvgIcon-root{
    height: 0.8em;
    width: 0.8em;
}

.validate-account .MuiFormLabel-root{
    color: #15576b;
}

.validate-account .MuiFormLabel-root{
    margin:-7px 0;
    color: rgba(0, 0, 0, 0.26) !important;
    font-size: 18px !important;
}

.validate-account .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 0px) scale(0.74) !important;
    color: #1E7995 !important;
}

.validate-account .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #1E7995 !important;
}

/* MuiInputBase-root MuiOutlinedInput-root  */

.validate-account .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: rgb(240, 77, 83) !important;
    border-left-width: 5px !important;
    border-left-style: solid !important;
}

.validate-account.MuiFormControl-marginNormal{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.validate-account .MuiInputBase-input{
    font-size:0.9rem !important;
    color: #495057;
    font-weight: 400;
}

.validate-account button:focus{
    outline: none !important;
}

.MuiPickersCalendar-week button:focus{
    outline: none !important;
}

.validate-account .MuiFormHelperText-contained{
    margin: 4px 14px;
}