.privacy_policy h2{
    color: #1E7995;
    font-size: 1.5rem;
    margin: 20px 0;
    text-decoration: underline;
}

.privacy_policy p{
    margin: 0 20px;
}

.privacy_policy ul{
    list-style-type: lower-alpha;
}