.api-contact-us :nth-child(2),
.api-contact-us :nth-child(3) {
  text-decoration: underline;
  color: green;
  cursor: pointer;
  display: table;
}

.api-contact-us :nth-child(4),
.api-contact-us :nth-child(5) {
  text-decoration: underline;
  color: #1E7995;
  cursor: pointer;
  display: table;
}

.api-contact-us h1 {
  font-size: 35px;
}

@media (max-width: 767px) {
  .api-contact-us h3 {
    text-decoration: underline;
  }
  .api-contact-us h1 {
    font-size: 22px;
  }
}
