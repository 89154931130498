.tc_inner_content .theme_header{
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 40px;
}

.tc_inner_content .theme_header .h3_title {
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
    color: #141414;
    letter-spacing: 0.2px;
    font-family: "Proxima Nova Alt Lt";
}

.tc_inner_content .custom_card {
    width: 100%;
    display: block;
    margin-top: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
}

.tc_inner_content .custom_card .card_head {
    height: 30px;
    background: #1e7995;
    border-top: 1px solid #1e7995;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tc_inner_content .custom_card .card_body {
    padding: 20px 50px;
    border: 1px solid #ebebeb;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #ffffff;
}

.tc_inner_content .custom_card .media_circle {
    border-radius: 50%;
    border: 1px solid #ebebeb;
    vertical-align: middle;
    margin-right: 25px;
}

.tc_inner_content .custom_card .media_img {
    width: 150px;
    height: 150px;
    display: inline-block;
    overflow: hidden;
}

.inner_image {
    width: 100%;
    display: block;
    object-fit: cover;
    min-height: 100%;
}

.tc_inner_content .custom_card .media-body {
    margin-top: 15px;
}

.media-body {
    flex: 1;
}

.tc_inner_content .custom_card .media-body .media_title {
    margin: 0px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-transform: inherit;
    color: #141414;
    font-family: "Proxima Nova Alt Rg";
}

.tc_inner_content .custom_card .media-body .media_info {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: inherit;
    color: #333;
    line-height: 26px;
    margin-top: 15px;
    font-family: "Proxima Nova Alt Lt";
}

.tc_inner_content .custom_card .tc_msg {
    padding: 15px 15px;
    border: 1px solid #f5f5f5;
    margin-top: 30px;
    background: #f5f5f5;
    border-radius: 5px;
}

.tc_inner_content .custom_card .tc_msg .tc_msg_img {
    width: 45px;
    height: 30px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 15px;
}

.tc_inner_content .custom_card .tc_msg .media-body {
    margin-top: 0px;
}

.tc_inner_content .custom_card .tc_msg .tc_msg_info {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #333;
    line-height: 26px;
    font-style: italic;
    font-family: "Proxima Nova Alt Lt";
}

.tc_inner_content .custom_card .card_footer {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
}

.tc_inner_content .custom_card .card_footer .tc_anchor {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    padding: 10px 30px;
    text-transform: inherit;
    background: #1e7995;
    border-radius: 25px;
    color: #ffffff;
    position: relative;
    transition: all 0.5s ease-in-out;
    font-family: "Proxima Nova Alt Rg";
}

.tc_inner_content .custom_card .card_footer .sign_form_icon {
    width: 15px;
    height: 15px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.align-middle {
    vertical-align: middle !important;
}

.tc_inner_content {
    width: calc(100% - 370px);
    display: block;
    margin: 40px auto;
}

.tc_inner_wrap {
    width: 100%;
    display: block;
    float: left;
    position: relative;
    padding: 0px 40px;
}

.form_8821_contract_wrapper{
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.height_theme {
    height: calc(100vh - 60px);
}

.h-100 {
    height: 100% !important;
}

@media only screen and (max-width: 990px) {
     .tc_inner_content {
      width: 100%;
    }
     .tc_inner_content .custom_card .media_img {
      width: 100px;
      height: 100px;
    }
     .tc_inner_content .custom_card .media-body .media_title {
      font-size: 20px;
    }
     .tc_inner_content .custom_card .tc_msg .tc_msg_img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  
  @media only screen and (max-width: 599px) {
     .tc_inner_wrap {
      padding: 0px 5px;
    }
    .main_header .navbar {
      padding: 20px 20px;
    }
  
     .tc_inner_content .custom_card {
      margin-top: 20px;
    }
     .tc_inner_content .custom_card .card_body {
      padding: 20px 25px;
    }
    .user_profile_8821_form {
      display: block !important;
      text-align: center !important;
    }
     .tc_inner_content .custom_card .media_circle {
      margin-right: 0;
    }
  
     .tc_inner_content .custom_card .media-body .media_info {
      margin: 0px;
      font-size: 13px;
      line-height: 24px;
      margin-top: 5px;
    }
     .tc_inner_content .custom_card .tc_msg {
      padding: 15px 10px;
      margin-top: 20px;
    }
  
     .tc_inner_content .custom_card .tc_msg .tc_msg_info {
      margin: 0px;
      font-size: 12px;
      line-height: 22px;
    }
     .tc_inner_content .custom_card .card_footer .tc_anchor {
      font-size: 12px;
    }
     .tc_inner_content .custom_card .card_footer {
      margin-top: 20px;
    }
  }
  
  @media screen and (max-width: 990px) and (min-width: 600px) {
     .tc_inner_content .custom_card .card_body {
      padding: 20px 30px;
    }
  
     .tc_inner_content .custom_card .media-body {
      margin-top: 7px;
    }
     .tc_inner_content .custom_card .media-body .media_info {
      font-size: 12px;
      line-height: 21px;
      margin-top: 7px;
    }
  
     .tc_inner_content .custom_card .tc_msg .tc_msg_info {
      font-size: 12px;
      line-height: 22px;
    }
     .tc_inner_wrap {
      margin-top: 0px;
    }
     .tc_inner_content {
      margin: 0px auto;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 991px) {
     .tc_inner_content {
      width: calc(100% - 170px);
    }
     .tc_inner_wrap {
      margin-top: 0px;
    }
     .tc_inner_content {
      margin: 0px auto;
    }
     .tc_inner_content .theme_header {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 1200px) {
     .tc_inner_content {
      width: calc(100% - 270px);
    }
  }

  .take_me_to_home_btn{
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    padding: 10px 30px;
    text-transform: inherit;
    background: #1e7995;
    border-radius: 25px;
    color: #ffffff;
    position: relative;
    transition: all 0.5s ease-in-out;
    font-family: "Proxima Nova Alt Rg";
}

.take_me_to_home_btn:hover{
  color: #ffffff !important;
}
  